// import request grouping here below
import auth from './auth-request.js'
import msg from './msg-request.js'
import patient from './patient-request.js'
import task from './task-request.js'
export default {
  auth,
  msg,
  patient,
  task
}
